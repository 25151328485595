<template>
  <div class="container">
    <div class="form-card">
      <div class="card-head">
        <h4 class="card-title">Profil</h4>
      </div>
      <div class="card-body">
        <h5 class="section-title">Kişisel Bilgiler</h5>
        <div class="row mb-20">
          <div class="col-md-4">
            <div class="form-group">
              <label for="">Ad</label>
              <span class="fake-input">{{user.given_name}}</span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="">Soyad</label>
              <span class="fake-input">{{user.family_name}}</span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="">Telefon</label>
              <span class="fake-input">{{user.mobile_phone}}</span>
            </div>
          </div>
        </div>
        <h4 class="section-title">Hesap Bilgileri</h4>
        <div class="row mb-20">
          <div class="col-md-4">
            <div class="form-group">
              <label for="">Kullanıcı Adı</label>
              <span class="fake-input">{{user.username}}</span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="">Eposta</label>
              <span class="fake-input">{{user.email}}</span>
            </div>
          </div>
        </div>
        <h4 class="section-title">Ayarlar</h4>
        <div class="row">
          <div class="col-md-2">
            <div class="form-group">
              <label for="">Bölge</label>
              <select class="form-select" v-model="user.region">
                <option selected disabled value="">Bölge Seç</option>
                <option value="TR">TÜRKİYE</option>
                <option value="EN">USA</option>
              </select>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label for="">Dil</label>
              <select class="form-select" v-model="user.language">
                <option selected disabled value="">Dil Seç</option>
                <option value="tr">TÜRKÇE</option>
                <option value="en">ENGLISH</option>
              </select>
            </div>
          </div>
          <div class="col-md-4 position-relative">
            <div class="mode">
              <span class="title">Mode</span>
              <div class="form-check switch-checkbox">
                <input
                  type="checkbox"
                  id="mode-checkbox"
                  class="form-check-input"
                  v-model="mode"
                  @change="modeSwtich"
                />
                <label class="form-check-label" for="mode-checkbox">
                  {{ mode ? "Light" : "Dark" }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Done
    @closeModal="doneModalClose"
    v-if="isCloseModal"
    :doneMsg="popupText"
    :doneTitle="popupTitle"
    @click="closeModal"
    link="/users"
  />
</template>
<script>
import Done from "@/components/Done.vue";
export default {
  data() {
    return {
      isCloseModal: false,
      popupText: "",
      popupTitle: "",
      user:{},
      mode: true,
    };
  },
  components: { Done },
  
  created() {
    this.user = this.$store.state.auth.user;
    let mode = this.getCookie("theme-mode");
    if (mode) {
      mode == "light" ? (this.mode = true) : (this.mode = false);
    }
    this.$store.commit("theme/addToBreadcrumps", [
      {
        title: "Anasayfa",
        path: "/",
      },
      {
        title: "Profil",
      },
    ]);
  },
  methods: {
    doneModalClose(value) {
      this.isCloseModal = value;
    },
    modeSwtich() {
      if (this.mode) {
        document.querySelector("body").classList.add("light");
        document.querySelector("body").classList.remove("dark");
        this.setCookie("theme-mode", "light")
        this.$store.state.theme.theme_mode = "light";
      } else {
        document.querySelector("body").classList.remove("light");
        document.querySelector("body").classList.add("dark");
        this.setCookie("theme-mode", "dark")
        this.$store.state.theme.theme_mode = "dark";
      }
    },
  },
};
</script>